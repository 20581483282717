<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">
              {{ $t('MENU.MAIN_ASSETS.TRANSPORT.BRAND') }}
            </h3>
          </div>
          <div class="card-button">
            <v-btn to="/addbrand" color="primary">
              {{ $t('MENU.MAIN_ASSETS.TRANSPORT.BRAND') }} +</v-btn
            >
          </div>
        </div>

        <div class="card-body">
          <v-data-table
            :headers="headers"
            :items="allBrands"
            :items-per-page="10"
            :page.sync="page"
            class="row-pointer"
            single-select
            @click:row="rowClick"
            item-key="id"
            hide-default-footer
            no-data-text="Malumot kiritilmagan"
          >
            <template v-slot:[`item.index`]="{ item }">
              {{
                allBrands
                  .map(function (x) {
                    return x.id
                  })
                  .indexOf(item.id) + 1
              }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <action :index="item" />
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
/* action */
import action from './action.vue'
export default {
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      perPage: 10,
      currentPage: 1,
      isOpen: false,
      filterName: null,
      sortBy: '',
      sortable: true,
      sortByFormatted: true,
      filterByFormatted: true,
      filterOn: []
    }
  },
  beforeCreate() {
    this.$store.dispatch('getAllBrands')
  },
  computed: {
    headers() {
      return [
        {
          text: '#',
          value: 'index'
        },
        {
          text: this.$t('TABLE_HEADER.NAME'),
          value: 'name'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    },
    allBrands() {
      const data = this.$store.state.requests.allBrands
      return data.reverse()
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        row.select(false)
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.MAIN_ASSETS.TITLE') },
      { title: this.$t('MENU.MAIN_ASSETS.TRANSPORT.TITLE') },
      { title: this.$t('MENU.MAIN_ASSETS.TRANSPORT.BRAND') }
    ])
  },
  components: {
    action
  }
}
</script>
