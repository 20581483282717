<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <div class="navi-link" dark v-bind="attrs" v-on="on">
          <span class="navi-icon">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.UPDATE') }} </span>
        </div>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('ACTION.UPDATE') }}</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            dense
            outlined
            v-model="data.name"
            label="Brand nomi"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialog = false">Yopish</v-btn>
          <v-btn color="success" @click="submitUpdate">Saqlash</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      data: {}
    }
  },
  mounted() {
    this.data = JSON.parse(JSON.stringify(this.actionObj))
  },
  methods: {
    submitUpdate() {
      const data = {
        name: this.data.name
      }
      this.dialog = false
      this.$store.dispatch('updateBrand', { id: this.data.id, data })
    }
  },
  props: {
    actionObj: Object
  }
}
</script>

<style scoped>
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem !important;
}
</style>
